import { environment } from '../../environments/environment';

const PartitionKey = 'MethodNamespace';
const SortKey = 'ClusterDestinationOrigin';

function splitPartitionKey(key: string) {

	const split = key.split('/', 2);

	return {method: split[0], namespace: split[1]};
}

function splitSortKey(key: string) {
	const split = key.split('/', 3);

	return {cluster: split[0], destination: split[1], origin: split[2]};
}

function getStringValueFromDBItem(item: any, field: string) {
	if (item.hasOwnProperty(field)) {
		return item[field]['S'];
	}

	return null;
}

function getBoolValueFromDBItem(item: any, field: string) {
	if (item.hasOwnProperty(field)) {
		return item[field]['BOOL'];
	}

	return null;
}

export class CredentialBase {

	//Actual null doesn't work with the form controls
	disabled                  : boolean|null = false;

	method                    : string = '';
	namespace                 : string = '';
	cluster                   : string = '';
	origin                    : string = '';
	destination               : string = '';
	strategy                  : string = '';
	forcedOrigin              : string = '';
	optIn                     : string = '';
	twilioAccountSid          : string = '';
	twilioMessagingServiceSid : string = '';
	comment                   : string = '';

	constructor() {
		//TODO - Should this default to Yes?
		this.optIn = 'Yes';
	}
}

export class Credential extends CredentialBase {

	constructor(values?:CredentialBase) {
		super();

		//typescript barfs if we try to iterate the properties, sigh, just copy them for now
		if (values) {
			this.disabled = values.disabled;
			this.method = values.method;
			this.namespace = values.namespace;
			this.cluster = values.cluster;
			this.origin = values.origin;
			this.destination = values.destination;
			this.strategy = values.strategy;
			this.forcedOrigin = values.forcedOrigin;
			this.optIn = values.optIn;
			this.twilioAccountSid = values.twilioAccountSid;
			this.twilioMessagingServiceSid = values.twilioMessagingServiceSid;
			this.comment = values.comment;
		}

	}

	public static fromDBItem(item: any): Credential {

		const credential = new Credential();

		const pkey = splitPartitionKey(item[PartitionKey]['S']);
		credential.method = pkey.method;
		credential.namespace = pkey.namespace;

		const skey = splitSortKey(item[SortKey]['S']);
		credential.cluster = skey.cluster;
		credential.destination = skey.destination;
		credential.origin = skey.origin;

		credential.comment                   = getStringValueFromDBItem(item, 'Comment');
		credential.forcedOrigin              = getStringValueFromDBItem(item, 'ForcedOrigin');
		credential.optIn                     = getStringValueFromDBItem(item, 'EnforceOptIn');
		credential.strategy                  = getStringValueFromDBItem(item, 'OriginStrategy');
		credential.twilioAccountSid          = getStringValueFromDBItem(item, 'TwilioAccountSID');
		credential.twilioMessagingServiceSid = getStringValueFromDBItem(item, 'TwilioMessagingServiceSID');

		credential.disabled                  = getBoolValueFromDBItem(item,'Disabled');

		return credential;

	}

	public matchingKey(item: Credential) : boolean {

		const pkey = this.partitionKey();
		const skey = this.sortKey();

		if (pkey == item.partitionKey() && skey == item.sortKey()) {
			return true;
		}

		return false;

	}

	public partitionKey():string {
		return [this.method, this.namespace].join('/');

	}

	public sortKey(): string {
		return [this.cluster, this.destination, this.origin].join('/');
	}
	
	public toDBItem(keyOnly?:boolean) {

		const methodNamespace = this.partitionKey();
		const clusterDestinationOrigin = this.sortKey();

		const item:{[key:string]:any} = {};
		item[PartitionKey] = {'S': methodNamespace};
		item[SortKey] = {'S': clusterDestinationOrigin};

		if (keyOnly) {
			return item;
		}

		if (this.comment) {
			item['Comment'] = {'S': this.comment};
		}

		if (this.forcedOrigin) {
			item['ForcedOrigin'] = {'S': this.forcedOrigin};
		}

		if (this.optIn) {
			item['EnforceOptIn'] = {'S': this.optIn};
		}

		if (this.strategy) {
			item['OriginStrategy'] = {'S': this.strategy};
		}

		if (this.twilioAccountSid) {
			item['TwilioAccountSID'] = {'S': this.twilioAccountSid};
		}

		if (this.twilioMessagingServiceSid) {
			item['TwilioMessagingServiceSID'] = {'S': this.twilioMessagingServiceSid};
		}

		if (this.disabled != null) {
			item['Disabled'] = {'BOOL': this.disabled};
		}

		return item;

	}



	public validate() : string[] {

		const errors: string[] = [];

		for (let field in this) {
			if (['disabled', 'forcedOrigin', 'comment'].includes(field)) {
				continue;
			}

			if (this[field] === '') {
				errors.push(`${field} is required`);
			}
		}
		
		let namespace_pattern = '^(\\*|[a-z0-9]{63})$';
		if (this.namespace != '' && ! this.namespace.match(namespace_pattern)) {
			errors.push(`Namespace does not match pattern ${namespace_pattern}`);
		}

		let destination_pattern = '^(\\*|\\+\\d+(\\|\\+\\d+)*)$';
		if (this.destination != '' && ! this.destination.match(destination_pattern)) {
			errors.push(`Destination does not match pattern ${destination_pattern}`);
		}

		let twilioAccountSid_pattern = '^(-|(?:AC[0-9a-f]{32}|AC-[0-9A-Za-z]+))$';
		if (this.twilioAccountSid != '' && ! this.twilioAccountSid.match(twilioAccountSid_pattern)) {
			errors.push(`Twilio account SID does not match pattern ${twilioAccountSid_pattern}`);
		}

		let twilioMessagingServiceSid_pattern = '^(-|MG[0-9a-f]{32})$';
		if (this.twilioMessagingServiceSid != '' && ! this.twilioMessagingServiceSid.match(twilioMessagingServiceSid_pattern)) {
			errors.push(`Twilio messaging service SID does not match pattern ${twilioMessagingServiceSid_pattern}`);
		}

		return errors;
	}
	
}
