import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AwsCredentialIdentity } from '@smithy/types';

import { AwsCredentialsService } from '../services/aws-credentials.service';

import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

	constructor(
		private awsCreds: AwsCredentialsService,
		private router: Router
	){

		if (router.url == '/logout') {
			awsCreds.logout();
			router.navigate(['/login']);
		}
	
	}

	public errorMessage = '';

	startSAML(role: string) {
			window.location.href = `/saml/login?Role=${role}`;
	}

}
