<div class="modal-header">
	<h1>Errors</h1>
	<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
	<p *ngFor="let error of errors">{{ error }}</p>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-outline-dark" (click)="modal.close('Close click')">Close</button>
</div>
