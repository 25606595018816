import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormBuilder } from '@angular/forms';

import {MatButtonModule} from '@angular/material/button';

import { environment } from '../../environments/environment';

import { Credential, CredentialBase } from '../models/credential.model';
import { SelectOption } from '../models/select-option';
import { ErrorDisplayComponent } from '../error-display/error-display.component';
import { CredentialService } from '../services/credential.service';
import { AwsCredentialsService } from '../services/aws-credentials.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit',
  standalone: true,
  imports: [ReactiveFormsModule, MatButtonModule],
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})

export class EditComponent implements OnInit {

	public clusters:SelectOption[] = [];

	public disabledOptions: SelectOption[] = [{value:'NULL',label:'NULL'},{value:false,label:'False'},{value:true,label:'True'}];
	public optInOptions:SelectOption[]    = [ {value:'Yes'},{value:'No'} ];
	public originOptions:SelectOption[]   = [ {value:'Force'},{value:'Clear'}, {value:'Preserve'}, {value:'Reject'}];

	@Input() credential: Credential|null;
	@Input() editing: boolean;

	@Output() cancel: EventEmitter<any> = new EventEmitter();
	@Output() reloadParent: EventEmitter<any> = new EventEmitter();

	constructor (
		private formBuilder: FormBuilder,
		private modalService: NgbModal,
		private service: CredentialService,
		private awsCreds: AwsCredentialsService
	) {

		for (let cluster of awsCreds.getClusters()) {
			this.clusters.push({value:cluster});
		}

		this.editing = false;
		this.credential = null;

	}

	ngOnInit() {

		//TODO - Need to set the default cluster
		//if (! this.editing) {
		//	this.credential.cluster = this.clusters[0]
		//}

		this.credentialForm.setValue(this.credential as Credential);

		const value = this.credentialForm.value;
		if (value.disabled === null) {

			//The <select> element doesn't understand null as a value
			//@ts-ignore
			this.credentialForm.patchValue({disabled:'NULL'});
			
		}

	}

	private credentialModel = new CredentialBase();

	credentialForm = this.formBuilder.group(this.credentialModel);

	public async onSubmit():Promise<void> {

		const value = this.credentialForm.value;

		//@ts-ignore
		if (value.disabled === 'NULL') {
			value.disabled = null;

		//@ts-ignore
		} else if(value.disabled == 'true') {
			value.disabled = true;
		//@ts-ignore
		} else if(value.disabled == 'false') {
			value.disabled = false;
		}

		const credential = new Credential(this.credentialForm.value as CredentialBase);

		const errors = await this.service.save(credential, this.editing ? this.credential as Credential : undefined);

		if (errors.length) {
			const modal = this.modalService.open(ErrorDisplayComponent, {centered: true});
			modal.componentInstance.setErrors(errors);

			return;
		} 

		this.reloadParent.emit();

	}

}
