import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-display.component.html',
  styleUrl: './error-display.component.scss'
})

export class ErrorDisplayComponent {

	errors: string[] = [];

	constructor(
		public modal: NgbActiveModal 
	){}


	setErrors(errors: string[]) {
		this.errors = errors;
	}

}
