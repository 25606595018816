<form [formGroup]="credentialForm" (ngSubmit)="onSubmit()">
<div class="row">
	<div class="col-2">
		<label class="form-label" for="method">Method</label>
		<select class="form-control" id="method" formControlName="method">
			<option>VOICE</option>
			<option>SMS</option>
		</select>
	</div>
	<div class="col-2">
		<label class="form-label" for="cluster">Cluster</label>
		<select class="form-control" id="cluster" formControlName="cluster">
			@for (opt of clusters; track $index) {
				<option value="{{opt.value}}">{{opt.label ? opt.label : opt.value}}</option>
			}
		</select>
	</div>
	<div class="col-8">
		<label class="form-label" for="namespace">Namespace</label>
		<input type="text" class="form-control" id="namespace" formControlName="namespace"  pattern="[a-zA-Z ]*">
	</div>
</div>
<div class="row mt-4">
	<div class="col-2">
		<label class="form-label" for="disabled">Disabled</label>
		<select class="form-control" id="disabled" formControlName="disabled">
			@for (opt of disabledOptions; track $index) {
				<option value="{{opt.value}}">{{opt.label ? opt.label : opt.value}}</option>
			}
		</select>
	</div>
	<div class="col-2">
		<label class="form-label" for="optIn">Enforce opt in</label>
		<select class="form-control" id="optIn" formControlName="optIn">
			@for (opt of optInOptions; track $index) {
				<option value="{{opt.value}}">{{opt.label ? opt.label : opt.value}}</option>
			}
		</select>
	</div>
	<div class="col-4">
		<label class="form-label" for="destination">Destination</label>
		<input type="text" class="form-control" id="destination" formControlName="destination">
	</div>
	<div class="col-4">
		<label class="form-label" for="origin">Origin</label>
		<input type="text" class="form-control" id="origin" formControlName="origin">
	</div>
</div>
<div class="row mt-4">
	<div class="col-2">
		<label class="form-label" for="strategy">Origin strategy</label>
		<select class="form-control" id="strategy" formControlName="strategy">
			@for (opt of originOptions; track $index) {
				<option value="{{opt.value}}">{{opt.label ? opt.label : opt.value}}</option>
			}
		</select>
	</div>
	<div class="col-2">
		<label class="form-label" for="forcedOrigin">Forced origin</label>
		<input type="text" class="form-control" id="forcedOrigin" formControlName="forcedOrigin">
	</div>
	<div class="col">
		<label class="form-label" for="twilioAccountSid">Twilio account SID</label>
		<input type="text" class="form-control" id="twilioAccountSid" formControlName="twilioAccountSid">
	</div>
	<div class="col">
		<label class="form-label" for="twilioMessagingServiceSid">Twilio messaging service SID</label>
		<input type="text" class="form-control" id="twilioMessagingServiceSid" formControlName="twilioMessagingServiceSid">
	</div>
</div>
<div class="row mt-4">
		<label class="form-label" for="comment">Comment</label>
		<input type="text" class="form-control" id="comment" formControlName="comment">
</div>
<div class="row" style="margin-top:10px">
	<div class="col">
		<button mat-raised-button color="primary" type="submit">Save</button>
	</div>
	<div class="col">
		<button mat-raised-button color="primary" (click)="cancel.emit($event)">Cancel</button>
	</div>
</div>
</form>
