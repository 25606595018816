import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Credential } from '../models/credential.model';

@Component({
  selector: 'app-confirm-delete',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './confirm-delete.component.html',
  styleUrl: './confirm-delete.component.scss'
})

export class ConfirmDeleteComponent {

	@Input() credential!: Credential;

	constructor(
		public modal: NgbActiveModal
	){}

}
