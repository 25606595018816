import { Routes } from '@angular/router';
import { ListingComponent } from './listing/listing.component';
import { LoginComponent } from './login/login.component';
import { LocalSAMLComponent } from './local-saml/local-saml.component';

export const routes: Routes = [
	{
		path: '',
		component: ListingComponent
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'logout',
		component: LoginComponent
	},

	//These get hidden when deployed to AWS via Lambda@Edge
	{
		path: 'saml/login',
		component: LocalSAMLComponent
	},
	{
		path: 'saml/response',
		component: LocalSAMLComponent
	}
];
