<div>
 <button style="display:inline-block" (click)="logout()" mat-raised-button color="primary" class="float-end">Logout</button>
 <h1>{{ title }}</h1>
</div>
<div class="d-flex flex-column vh-100">
	<div class="flex-grow-1">
		<div [ngClass]="{'h-50':editing, 'h-100':!editing}">
			<div class="d-flex flex-column h-100">
				<div>
					<mat-form-field class="filter-form-field">
						<mat-label>Filter</mat-label>
						<input matInput type="text" [(ngModel)]="filterValue" (ngModelChange)="applyFilter()">
						@if (filterValue) {
							<button matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
								<span class="bi bi-x"></span>
							</button>
						}
					</mat-form-field>
				</div>
				<div class="flex-grow-1" style="overflow:auto;">
					<table mat-table [dataSource]="dataSource" matSort>
						<ng-container matColumnDef="edit">
							<th mat-header-cell *matHeaderCellDef mat-sort-header></th>
							<td mat-cell *matCellDef="let element" style="white-space:nowrap">
								<span title="Edit" class="bi bi-pencil-square" (click)="editCredential(element)" color="primary" *ngIf="! editing"></span>
								<span title="Delete" class="bi bi-x-circle" (click)="deleteCredential(element)" color="primary" *ngIf="! editing"></span>
								<span title="Clone row" class="bi bi-clipboard-plus" (click)="cloneCredential(element)" color="primary" *ngIf="! editing"></span>
							</td>
						</ng-container>
						<ng-container matColumnDef="disabled">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Disabled</th>
							<td mat-cell *matCellDef="let element" [innerHTML]="(element.disabled === true ? 'true' : ( element.disabled === false ? 'false' : '')) | highlight :filterValue"></td>
						</ng-container>
						<ng-container matColumnDef="method">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Method</th>
							<td mat-cell *matCellDef="let element" [innerHTML]="element.method | highlight :filterValue"></td>
						</ng-container>
						<ng-container matColumnDef="namespace">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Namespace</th>
							<td style="white-space:nowrap" mat-cell *matCellDef="let element" title="{{element.namespace}}" class="copy">
								<span title="copy namespace to clipboard" class="bi bi-copy" (click)="copyToClipboard($event, element.namespace)" style="cursor:pointer"></span>
								<div [innerHTML]="element.namespace | highlight :filterValue:6:6 "></div>
							</td>
						</ng-container>
						<ng-container matColumnDef="cluster">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Cluster</th>
							<td mat-cell *matCellDef="let element" [innerHTML]="element.cluster | highlight :filterValue"></td>
						</ng-container>
						<ng-container matColumnDef="origin">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Origin</th>
							<td mat-cell *matCellDef="let element" [innerHTML]="element.origin | highlight :filterValue"></td>
						</ng-container>
						<ng-container matColumnDef="destination">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Destination</th>
							<td mat-cell *matCellDef="let element" [innerHTML]="element.destination | highlight :filterValue"></td>
						</ng-container>
						<ng-container matColumnDef="strategy">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Strategy</th>
							<td mat-cell *matCellDef="let element" [innerHTML]="element.strategy | highlight :filterValue"></td>
						</ng-container>
						<ng-container matColumnDef="forcedOrigin">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Forced origin</th>
							<td mat-cell *matCellDef="let element" [innerHTML]="element.forcedOrigin | highlight :filterValue"></td>
						</ng-container>
						<ng-container matColumnDef="optIn">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Opt in</th>
							<td mat-cell *matCellDef="let element" [innerHTML]="element.optIn | highlight :filterValue"></td>
						</ng-container>
						<ng-container matColumnDef="twilioAccountSid">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Account SID</th>
							<td style="white-space:nowrap" mat-cell *matCellDef="let element" title="{{element.twilioAccountSid}}" class="copy">
								<span title="copy Twilio Account SID to clipboard" class="bi bi-copy" (click)="copyToClipboard($event, element.twilioAccountSid)" style="cursor:pointer"></span>
								<div [innerHTML]="element.twilioAccountSid | highlight : filterValue:10"></div>
							</td>
						</ng-container>
						<ng-container matColumnDef="twilioMessagingServiceSid">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Messaging SID</th>
							<td style="white-space:nowrap" mat-cell *matCellDef="let element" title="{{element.twilioMessagingServiceSid}}" class="copy">
								<span title="copy Twilio Messaging Service SID to clipboard" class="bi bi-copy" (click)="copyToClipboard($event, element.twilioMessagingServiceSid)" style="cursor:pointer"></span>
								<div [innerHTML]="element.twilioMessagingServiceSid | highlight : filterValue:10"></div>
							</td>
						</ng-container>
						<ng-container matColumnDef="comment">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Comment</th>
							<td mat-cell *matCellDef="let element" [innerHTML]="element.comment | highlight : filterValue"></td>
						</ng-container>
					
						<tr mat-header-row *matHeaderRowDef="headers"></tr>
						<tr
								mat-row
								*matRowDef="let row; columns: headers;"
						></tr>
					</table>
				</div>
				<div class="h-25">
					<button style="display:inline-block" (click)="addCredential()" mat-raised-button color="primary" *ngIf="! editing">Add credential</button>
					<mat-paginator style="display:inline-block" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of credentials"></mat-paginator>
				</div>
			</div>
		</div>
		<div class="h-50" *ngIf="editing" style="background-color:silver">
			<div style="padding:10px">
				<div style="border:1px solid black; background-color:white; margin:10px; padding:10px;">
					<app-edit
							[credential]="currentCredential"
							[editing]="editingExistingCredential"
							(cancel)=cancelEdit()
							(reloadParent)=reload()
					></app-edit>
				</div>
			</div>
		</div>
	</div>
<div>
