import { Component } from '@angular/core';
import { Router } from '@angular/router';

//This component is purely for local dev
//The URL's to access it are overridden by lambda@edge when
//actually deployed

@Component({
  selector: 'app-local-saml',
  standalone: true,
  imports: [],
  templateUrl: './local-saml.component.html',
  styleUrl: './local-saml.component.scss'
})
export class LocalSAMLComponent {

	constructor(
		private router: Router
	) {

		if (router.url == '/saml/login') {

			localStorage.setItem('Role', 'foo');
			router.navigate(['/saml/response']);

		} else {

			localStorage.setItem('awscredentials', JSON.stringify({
				'accessKeyId':'localhost',
				'secretAccessKey':'localhost',
				'sessionToken':'localhost'
			}));

			localStorage.setItem('tablename','localdev');
			localStorage.setItem('clusters',JSON.stringify(['LOCALDEV']));

			router.navigate(['/']);


		}

	}

}
