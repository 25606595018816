import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import {DataSource} from '@angular/cdk/collections';
import {Observable, ReplaySubject} from 'rxjs';

import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

import { DynamoDBClient } from '@aws-sdk/client-dynamodb';

import { AwsCredentialsService } from '../services/aws-credentials.service';
import { CredentialService } from '../services/credential.service';
import { Credential } from '../models/credential.model';

import { EditComponent } from '../edit/edit.component';

import { ErrorDisplayComponent } from '../error-display/error-display.component';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { HighlightPipe } from '../pipes/highlight.pipe';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-listing',
  standalone: true,
  imports: [ FormsModule, MatTableModule, MatSortModule, MatPaginatorModule, MatFormFieldModule, MatInputModule, EditComponent, CommonModule, MatButtonModule, MatIconModule, HighlightPipe ],
  templateUrl: './listing.component.html',
  styleUrl: './listing.component.scss'
})

//TODO - Add a "Clear filter" button
//     - Add a "reload from dynmamodb" button, although this could just be f5
//     - Show the session expiration
//     - Detect if in Admin or Viewer mode, and only display the data modification buttons if in Admin
//     - Concurrency detection, change the PutItem call to be a conditional update, and add a sequence so that
//       updates from stale data will fail
//     - resolved namespaces / twilio account as a multi height cell
//       ability to filter by these as well
//       Except, the raw credentials cannot be retrieved as this would
//       expose them to the viewer role
//       Going to require a rethink
//       https://aws.amazon.com/blogs/networking-and-content-delivery/authorizationedge-how-to-use-lambdaedge-and-json-web-tokens-to-enhance-web-application-security/?
//       The SAML POST lambda@edge handler, could possibly store a JWT in local storage that can be reused

export class ListingComponent implements OnInit {

	public editing: boolean = false;
	public filterValue: string = '';

	public title: string = environment.title;

	public items: Credential[] = [];

	dataSource = new MatTableDataSource(this.items);

	@ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

	private sortColumn = '';
	private sortDescending = false;

	public currentCredential!: Credential;
	public editingExistingCredential!: boolean;

	constructor (
		private awsCreds: AwsCredentialsService,
		private service: CredentialService,
		private router: Router,
		private modalService: NgbModal
	) {
	}

	public mode = 'listing';

	public headers = [
		"edit",
		"disabled",
		"method",
		"namespace",
		"cluster",
		"origin",
		"destination",
		"strategy",
		"forcedOrigin",
		"optIn",
		"twilioAccountSid",
		"twilioMessagingServiceSid",
		"comment"
	];

	public async ngOnInit() {
		if (! await this.awsCreds.hasCredentials()) {
			this.router.navigate(['/login']);
		}

		await this.service.fetchItems();

		this.items = this.service.getItems();
		this.dataSource.data = this.items;

	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	clearFilter() {
		this.filterValue = '';
		this.applyFilter();
	}

	applyFilter() {

		this.dataSource.filter = this.filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			 this.dataSource.paginator.firstPage();
		}
	}

	public logout() {
			this.router.navigate(['/logout']);
	}


	public async reload() {
		this.dataSource.data = this.items = this.service.getItems();
		this.editing = false
	}

	public addCredential() {
		this.currentCredential = new Credential();
		this.editing = true;
		this.editingExistingCredential = false;
	}

	public editCredential(element: Credential) {
		this.currentCredential = element;
		this.editing = true;
		this.editingExistingCredential = true;
	}

	public cloneCredential(element: Credential) {
		this.currentCredential = new Credential(element);
		this.editing = true;
		this.editingExistingCredential = false;
	}

	public cancelEdit() {
		this.editing = false;
	}

	public deleteCredential(element: Credential) {
		const modal = this.modalService.open(ConfirmDeleteComponent, {centered: true});
		modal.componentInstance.credential = element;

		modal.closed.subscribe(async (confirmed: boolean) => {
			if (confirmed) {
				const errors = await this.service.delete(element);
				if (errors.length) {
					const modal = this.modalService.open(ErrorDisplayComponent, {centered: true});
					modal.componentInstance.setErrors(errors);
				} else {
					this.reload();
				}
			}
		});

	}

	public copyToClipboard(event:Event, value: string) {
		navigator.clipboard.writeText(value);
		if (event.target) {
			const target = event.target as HTMLElement;
			target.classList.remove('bi-copy');
			target.style.color = 'green';
			target.classList.add('bi-check-circle-fill');

			setTimeout(() => {
				target.classList.remove('bi-check-circle-fill');
				target.classList.add('bi-copy');
				target.style.color = '';
			},1000);
		}
	}

}
