import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name:'highlight',
	standalone: true
})


export class HighlightPipe implements PipeTransform {

	private encodeHTML(value:string) : string {
		return value.replaceAll(/[&<>"']/g, (char:string) => {
			let rep;
			switch(char) {
				case '&': rep = 'amp'; break;
				case '<': rep = 'lt'; break;
				case '>': rep = 'gt'; break;
				case '"': rep = 'quot'; break;
				case "'": rep = 'apos'; break;
			}
			return `&${rep};`;
		});

	}

	private highlight(value: string,prefixEllipsis:boolean=false,suffixEllipsis:boolean=false,noEncode:boolean=false) : string {

		let str = '<span class="searchresult">';

		if (prefixEllipsis) {
			str += '&hellip;';
		}

		if (noEncode) {
			str += value;
		} else {
			str += this.encodeHTML(value);
		}

		if (suffixEllipsis) {
			str += '&hellip;';
		}

		str += '</span>';

		return str;
	}

	//This pipe should be used in an [innerHTML] reference, not a direct {{ value | highlight :filterValue }} 
	//The truncate will transform the text but will perform a search against the original value (so has to all be in the one pipe)
	//transform(val,'', 10) - If the string is more than 10 characters long, would show the first 10 characters followed by ...
	//transform(val,'',null,10) - If the string is more than 10 characters long, would show ... followed by the last 10 characters
	//transform(val,'',4,8) - If the string is more than (4+8) characters long, would show the first four characters, ..., last 8 characters
	transform(value: any, searchTerm: any, truncateStartLength?: number, truncateEndLength?: number): string {

		if (value == "" || value === null || value === undefined) {
			return "";
		}

		let maxLength = 0;

		let endStartsFromIndex = 0;

		if (truncateStartLength) {
			maxLength += truncateStartLength;
		}
		if (truncateEndLength) {
			maxLength += truncateEndLength;
			endStartsFromIndex = value.length - truncateEndLength;
		}

		const isTruncated = maxLength > 0 && value.length > maxLength;

		//Note, although this LOOKS like an XSS problem, it isn't 
		//
		//The Angular DOM Sanitizer does not encode all entities, it ONLY removes XSS attacks
		//
		//value = '<strong>Test</strong>' does not go do '&lt;strong&gt;Test&lt;/strong&gt;'
		//
		//but
		//
		//value = '<strong>Test</strong> <script>alert('test');</script> more' goes to '<strong>Test</strong>;  more', ie the 'script' part gets removed

		let startIndex = 0;
		let finalString = [];
		let lastMatchTruncated = false;

		if (searchTerm != '' && searchTerm != null) {

			var re = new RegExp(searchTerm,'ig');
			
			for (let m of value.matchAll(re)) {

				lastMatchTruncated = false;

				const preMatch = value.substr(startIndex, m.index-startIndex);

				if (isTruncated) {
					if (preMatch) {
						if (truncateStartLength && startIndex < truncateStartLength) {
							//How much of preMatch is going to make it?
							 finalString.push(this.encodeHTML(preMatch.substr(0,truncateStartLength-startIndex)));

							 if ((startIndex + preMatch.length) > truncateStartLength && m.index <= truncateStartLength) {
								 //prematch would have been truncated
								 finalString.push('&hellip;');
							 }

							 startIndex = m.index;
						} else if(m.index > endStartsFromIndex) {

							const preMatchEndsIndex = startIndex + preMatch.length;

							finalString.push('&hellip;');
							finalString.push(value.substr(endStartsFromIndex,preMatchEndsIndex - endStartsFromIndex));
							finalString.push(this.highlight(m[0]));

							startIndex = m.index + m[0].length;

						}
					}

					//Does the search span the entire truncation?
					if (truncateStartLength && m.index <= truncateStartLength && endStartsFromIndex && (m.index + m[0].length) >= endStartsFromIndex) {
						startIndex = m.index + m[0].length;

						let highlightedMatch = this.encodeHTML(value.substr(m.index, truncateStartLength - m.index)) + '&hellip;' + this.encodeHTML(m[0].substr(endStartsFromIndex - m.index));

						finalString.push(this.highlight(highlightedMatch, false, false, true));

					} else if (truncateStartLength && startIndex < truncateStartLength) {
						//How much of the match is showing
						let visibleMatch = m[0].substr(0,truncateStartLength-startIndex);

						lastMatchTruncated = visibleMatch.length < m[0].length;

						finalString.push(this.highlight(visibleMatch,false,lastMatchTruncated));
						
						if (! lastMatchTruncated && (startIndex + m[0].length) >= truncateStartLength) {
							finalString.push('&hellip;');
							lastMatchTruncated = true;
						}

						startIndex += visibleMatch.length;

					} else if(truncateEndLength && startIndex < endStartsFromIndex) {

						if (m.index < endStartsFromIndex) {
							finalString.push(this.highlight(value.substr(endStartsFromIndex,m.index + m[0].length - endStartsFromIndex),true));
							lastMatchTruncated = true;
						} else {
							finalString.push('&hellip;');
							finalString.push(this.highlight(m[0]));
						}
						startIndex = m.index + m[0].length;

					}

				} else {
					finalString.push(this.encodeHTML(preMatch));
					finalString.push(this.highlight(m[0]));
					startIndex = m.index + m[0].length;
				}
			}
		}

		if (isTruncated) {
			if (truncateStartLength && startIndex < truncateStartLength) {
				finalString.push(this.encodeHTML(value.substr(startIndex,truncateStartLength - startIndex)) + '&hellip;');
				startIndex = endStartsFromIndex;
			}

			if (truncateEndLength && startIndex < value.length) {
				//We are definitely outputting SOMETHING here
				if (startIndex < endStartsFromIndex) {
					if (! lastMatchTruncated) {
						finalString.push('&hellip;');
					}
					startIndex = endStartsFromIndex;
				}

				finalString.push(this.encodeHTML(value.substr(startIndex)));
			}

		} else {
			finalString.push(this.encodeHTML(value.substr(startIndex)));
		}

		return finalString.join('');
	}
	
}
