import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { DynamoDBClient, DynamoDBClientConfig, ScanCommand, PutItemCommand, DeleteItemCommand, DynamoDBServiceException } from '@aws-sdk/client-dynamodb';

import { AwsCredentialsService } from './aws-credentials.service';
import { Credential } from '../models/credential.model';

@Injectable({providedIn:'root'})
export class CredentialService {

	private client!: DynamoDBClient;

	private items: Credential[];

	constructor(private credentials: AwsCredentialsService) {
		this.items = [];
	}

	public async setClient() {
		if (this.client) {
			return;
		}

		if (! await this.credentials.hasCredentials()) {
			return;
		}

		const config:DynamoDBClientConfig = {
			region:'us-east-1',
			credentials: this.credentials.getCredentials(),
		};

		if (environment.dynamoDbEndPointUrl) {
			config['endpoint'] = environment.dynamoDbEndPointUrl;
		}

		this.client = new DynamoDBClient(config);
	}

	public getItems(): Credential[] {
		return this.items;
	}

	public async fetchItems() {

		try {

			await this.setClient();

			const command = new ScanCommand({TableName: this.credentials.getTableName()});

			const response = await this.client.send(command);

			if (response && response.Items) {
				for (const item of response.Items) {
					this.items.push(Credential.fromDBItem(item));
				}
			}
		} catch(err) {
			console.log(err);
		}
	}

	public async delete(credential: Credential) : Promise<string[]> {

		let errors:string[] = [];

		try {
			const delCommand = new DeleteItemCommand({
				TableName: this.credentials.getTableName(),
				Key: credential.toDBItem(true)
			});
			const delResponse = await this.client.send(delCommand);

			for (let [index,item] of this.items.entries()) {
				if (item.matchingKey(credential)) {
					this.items.splice(index, 1);
					break;
				}
			}

		} catch(err) {
			if (err instanceof DynamoDBServiceException) {
				if (err.name == 'ValidationException') {
					errors.push(err.message);
				}
			} 

			console.log(err);
			errors.push('Failed to delete');
		}

		return new Promise((resolve, reject) => { return resolve(errors);});

	}

	public async save(credential: Credential, original?:Credential):Promise<string[]> {

		//TODO - Add metadata to the row. Ideally the date last modified and user modified
		//       A full log would require a separate table as deletes would lose the log

		const validationErrors = credential.validate();
		if (validationErrors.length) {
			return new Promise((resolve, reject) => { return resolve(validationErrors);});
		}

		let errors:string[] = [];

		//Either we are adding a new row, or the editing row is changing its primary key
		if (! original || ! original.matchingKey(credential)) {
			//Check to see if this would attempt to create a duplicate row
			for (let item of this.items) {
				if (item.matchingKey(credential)) {
					if (original) {
						errors.push(`Editing row but partition key "${credential.partitionKey()}" and sort key "${credential.sortKey()}" already exists in a different row`)
					} else {
						errors.push(`Adding new row but partition key "${credential.partitionKey()}" and sort key "${credential.sortKey()}" already exists`)
					}
					break;
				}
			}
		}

		if (errors.length) {
			return new Promise((resolve, reject) => { return resolve(errors);});
		}

		try {
			await this.setClient();

			const command = new PutItemCommand({
				Item: credential.toDBItem(),
				TableName: this.credentials.getTableName()
			});

			const response = await this.client.send(command);

			if (original) {

					if (! original.matchingKey(credential)) {
						//Need to delete the old item
						const delCommand = new DeleteItemCommand({
							TableName: this.credentials.getTableName(),
							Key: original.toDBItem(true)
						});
						const delResponse = await this.client.send(delCommand);
					}

					for (let [index, item] of this.items.entries()) {
						if (item.matchingKey(original)) {
							this.items[index] = credential;
							break;
						}
					}

			} else {
				this.items.push(credential);
			}

		} catch(err) {

			if (err instanceof DynamoDBServiceException) {
				if (err.name == 'ValidationException') {
					errors.push(err.message);
				}
			} 

			console.log(err);
			errors.push('Failed to save');
		}

		return new Promise((resolve, reject) => { return resolve(errors);});

	}

}
