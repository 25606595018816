<div class="modal-header">
	<h1>Confirm delete</h1>
	<button type="button" class="btn-close" aria-label="Close" (click)="modal.close(false)"></button>
</div>
<div class="modal-body" *ngIf="credential">
	<p>Are you sure you want to delete this item?</p>
	<p>{{ credential.method }} / {{ credential.namespace }}</p>
	<p>{{ credential.cluster }} / {{ credential.destination }} / {{ credential.origin }}</p>
	<p *ngIf="credential.comment">{{ credential.comment }}</p>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-outline-dark" (click)="modal.close(true)">Delete</button>
	<button type="button" class="btn btn-outline-dark" (click)="modal.close(false)">Close</button>
</div>
